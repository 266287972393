.navContainer {
  margin-left: auto;
  margin-right: auto;
}

.navbar {
  padding: 0px 10px;
  font-weight: bold;
}

.navbar li {
  display: inline-block;
  margin-left: 10px;
}

.navbar li a {
  color: #4d4643 !important;
}

.navbar li a:hover {
  color: #56c5a5 !important;
}

@media only screen and (min-width: 600px) {
  .navContainer {
    width: 70%;
  }
}
